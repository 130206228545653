<template>
  <el-dialog
    :visible.sync="dialogVisible"
    top="2%"
    width="90%"
    title="导出课程"
    :center="true"
    :before-close="doClose"
  >
    <section class="CourseTables">
      <div class="operationControl" style="border:0">
        <div class="searchbox">
          <div title="课程名称" class="searchboxItem">
            <span  class="itemLabel">课程名称:</span>
            <el-input
              clearable
              v-model="courseName"
              type="text"
              size="small"
              placeholder="请输入课程名称"
            />
          </div>
          <div title="培训类型" class="searchboxItem ci-full flexcc">
            <span class="itemLabel" >培训类型:</span>
            <tree
              ref="tree"
              @eventBtn="childBack"
              :ruleForm="ruleForm"
              size="small"
              @clearParams="clearParams"
              :modal="false"
              typeStu
            />
          </div>
          <div title="课程推送方向" class="searchboxItem">
            <span class="itemLabel" style="width: 145px;">课程推送方向:</span>
            <el-radio-group v-model="partnerCoursePay">
              <el-radio v-for="(item,index) in partnerCoursePayList" :key="index" :label="item.value">{{ item.label }}</el-radio>
            </el-radio-group>
          </div>
         <!-- <div title="资源提供者" class="searchboxItem ci-full">
          <span class="itemLabel" >资源提供者:</span>
          <el-select
            size="small"
            v-model="compId"
            :remote-method="getCompanyList"
            remote
            filterable
            clearable
            placeholder="请至少输入两个字搜索"
          >
            <el-option
              v-for="item in CompanyList"
              :key="item.compId"
              :label="item.compName"
              :value="item.compId"
            ></el-option>
          </el-select>
        </div>-->
          <div>
            <el-button class="bgc-bv" @click="getData()">查询</el-button>
          </div>
        </div>
      </div>
      <div class="framePage-scroll">
        <div class="ovy-a">
          <el-table
            ref="multipleTable"
            :data="tableData"
            :height="tableHeight"
            size="small"
            tooltip-effect="dark"
            row-key="courseId"
            style="width: 100%"
            @selection-change="change"
            stripe
            border
          >
            <el-table-column type="selection" width="50px" align="center"   :reserve-selection="true">
              
            </el-table-column>
            <el-table-column label="课程名称" align="center" show-overflow-tooltip prop="courseName" width="200"/>
            <el-table-column
              label="培训类型"
              align="center"
              show-overflow-tooltip
              prop="trainTypeNamePath"
            />
            <el-table-column
              label="行业类型"
              align="center"
              show-overflow-tooltip
              prop="industryNamePath"
            />
            <el-table-column label="岗位类型" align="center" show-overflow-tooltip prop="postName" />
            <el-table-column
              label="职业/工种"
              align="center"
              show-overflow-tooltip
              prop="occupationNamePath"
            />
            <el-table-column
              label="培训等级"
              align="center"
              show-overflow-tooltip
              prop="trainLevelName"
            />
            <el-table-column
                    label="总学时"
                    align="center"
                    show-overflow-tooltip
                    prop="lessonNum"
            />
            <el-table-column label="视频总时长" align="center" show-overflow-tooltip prop="duration">
              <template slot-scope="scope">{{ getTime(scope.row.duration) || "--" }}</template>
            </el-table-column>
            <!-- <el-table-column
            label="资源提供者"
            align="center"
            show-overflow-tooltip
            prop="compName"
            width="240"
          />-->
            <Empty slot="empty" />
          </el-table>
        </div>
      </div>
      <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData" />
      <div class="Batch">
        <div class="BatchBox">
          <div class="BatchBoxFirst">
            <p>推送课程</p>
            <p>（{{backArr.length}}）</p>
          </div>
          <div class="BatchBoxSecond ovy-a">
            <div class="BatchforBox">
              <div  v-for="(item, index) in backArr" :key="index" class="BatchforFlex">
               <div class="BatchImgBox" @click="deleteImg(item)">
                 <img :src="require('@/assets/close_.png')" alt="" @mouseover="transToWhite(index,$event)" @mouseleave="transToBlack(index,$event)">
               </div>
               <span style="padding-left:5px">{{item.courseName}}</span>
             </div>
            </div>
             
        </div>
      </div>
      </div>
      <span class="dialog-footer" style="margin-top:10px;display: inline-block;width: 100%;text-align:center ;">
        <div>
          <el-button class="bgc-bv" @click="doClose">取消</el-button>
          <el-button class="bgc-bv" @click="doOk">确定</el-button>
        </div>
      </span>
    </section>
  </el-dialog>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import tree from "@/components/treePopup";
import { mapGetters } from "vuex";
export default {
  name: "courseMoreTable",
  components: {
    Empty,
    PageNum,
    tree
  },
  mixins: [List],
  data() {
    return {
      compOwnerId:"",
      kpointName: "",
      traintype: "",
      courseOrigin: "",
      partnerCoursePay:"", // 课程推送方向
      partnerCoursePayList: [], // 课程推送方向 - 数据集合
      courseOriginList: [],
      multipleTable:[],
      props: {
        value: "id",
        label: "label",
        emitPath: false
      },
      dialogVisible: false,
      params: {}, // tree 参数
      ruleForm: {
        Trainingtype: ""
      },
      start: false,
      backArr:[],
      backArrall:[],
      compId:'',
      CompanyList:[],
      row:'',
      partnerId:'',
      compName:'',
      compid:"",
      courseName:""
    };
  },
  watch: {
    compOwnerId: function(val) {
      if (val == "") {
        this.CompanyList = [];
      }
    }
  },
  computed: {
    ...mapGetters({
      traintypeList: "getTraintype"
    })
  },
  created() {},
 updated(){
   setTimeout(()=>{
      this.tableData.forEach((item,index)=>{
      this.$refs.multipleTable.toggleRowSelection(item,this.backArr.some(one=>(item.courseId == one.courseId)))
   })
   },200)
  
 },
  methods: {
    /* 鼠标移入移出 */
    transToWhite(index,e) {
      e.target.src = require('@/assets/error.png')
    },
    transToBlack(index,e) {
      e.target.src = require('@/assets/close_.png')
    },
    // 培训类型回调
    childBack(params) {
      this.params = { ...params };
    },

    tinit() {
      this.getCourseOriginList();
      this.init();
    },
    change(rowArr){
        this.backArr = rowArr;
        this.backArrall = rowArr;
        this.multipleTable = rowArr;
    },
    showPopUp(row) {
      this.partnerId = row.partnerId
      this.dialogVisible = true;
      this.start = true;
      if(this.start){
          this.getData();
      }
    },
    getCourseOriginList() {
      const courseOriginList = this.$setDictionary("COURSEORGIN", "list");
      const list = [];
      for (const key in courseOriginList) {
        list.push({
          value: key,
          label: courseOriginList[key]
        });
      }
      this.courseOriginList = list;
      // 课程推送方向
      const partnerCoursePayList = this.$setDictionary("PARTNER_COURSE_PAY", "list");
      const list1 = [];
      for (const key in partnerCoursePayList) {
        list1.push({
          value: key,
          label: partnerCoursePayList[key]
        });
      }
      this.partnerCoursePayList = list1;
      this.partnerCoursePay = list1[1].value;
    },
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
        postId: this.postId,
        trainLevelId: this.trainLevelId,
        trainTypeId: this.trainTypeId,
        industryId: this.industryId,
        occupationId: this.occupationId,
        kpointName: this.kpointName || "",
        compOwnerId:this.compOwnerId || "",
        compId: this.compId,
        courseName: this.courseName
      };
      if (this.courseOrigin) {
        params.kpointOrigin = this.courseOrigin;
      }
      if (this.compId) {
        params.compId = this.compId;
      }
      if (this.params.trainFormId) {
        params.trainTypeId = this.params.trainFormId;
      }
      if (this.params.levelFormId) {
        params.trainLevelId = this.params.levelFormId;
      }
      if (this.params.occFormId) {
        params.occupationId = this.params.occFormId;
      }
      if (this.params.postFormId) {
        params.postId = this.params.postFormId;
      }
      if (this.params.industryFormId) {
        params.industryId = this.params.industryFormId;
      }
      if (this.start) {
        this.doFetch({
          url: "/biz/chinahrt/partner/course/page",
          params,
          pageNum
        });
      }
    },
    deleteImg(itemobj) {
        var newArr =  this.backArr.filter(item => item.kpointId != itemobj.kpointId);
        this.backArr = newArr;
         this.multipleTable.find(el => {
           if(el.kpointId == itemobj.kpointId) {
               this.$refs.multipleTable.toggleRowSelection(el, false);    
           }
         })
      },
     // 资源提供者
  /*  getCompanyList(query) {
      if (query.trim().length >= 2) {
        this.$post("/sys/company/queryCompanyList", { compName: query })
          .then(res => {
            if (res.status == 0) {
              this.CompanyList = res.data || [];
            }
          })
          .catch(() => {
            return;
          });
      } else {
        this.CompanyList = [];
      }
    },*/
    getTableHeight(opDom = true, page = true) {
      let tHeight = 760 - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35 + 1) * 16;
      if (opDom) {
        tHeight -=  40 + 0.675 * 16 + 1;
      }
      if (page) {
        tHeight -= 32;
      }
      this.tableHeight = tHeight;
    },
    doOk() {
      const { backArr } = this;
      if (backArr.length) {
          let courseList =[];
          backArr.map(el => {
              courseList.push(el.courseId)
          })
          this.$post("/biz/chinahrt/export/export", { partnerId: this.partnerId,courseList:courseList,partnerCoursePay: this.partnerCoursePay, })
              .then(res => {
                  window.open(res.data);
                  // this.$emit()
              })
              .catch(() => {
                  return;
              });
        /*this.$emit("eventBus", backArr);*/
        this.dialogVisible = false;
        this.backArr = [];
        this.$refs.multipleTable.clearSelection();
      } else {
        this.$message({
          message: "请选择要关联的课件",
          type: "warning"
        });
      }
    },
      clearParams() {
          this.params = {};
      },
    doClose() {
     this.dialogVisible = false;
      this.backArr = [];
      this.$refs.multipleTable.clearSelection();
    //   this.$emit("eventBus");
        this.ruleForm = {};
    },
    getTime(val) {
      if (!val) {
        return "0秒";
      }
      let h = parseInt(val / 60 / 60);
      let m = parseInt((val % 3600) / 60);
      let s = val % 60;
      if (h < 9) {
        h = "0" + h;
      }
      if (m < 9) {
        m = "0" + m;
      }
      if (s < 9) {
        s = "0" + s;
      }
      return (
        (h != "00" ? h + "时" : "") +
          (m != "00" ? m + "分" : "") +
          (s != "00" ? s + "秒" : "") || ""
      );
    }
  }
};
</script>
<style lang="less" scoped>
.CourseTables {
  .Batch {
    padding: 1rem 1rem;
  .BatchBox {
    display: flex;
    height: 120px;
    border: 1px solid #EBEEF5;
    border-radius: 5px;
    .BatchBoxFirst {
      width: 260px;
      background: #f2f7fd;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    .BatchBoxSecond {
      width: 100%;
      .BatchforBox {
        display: flex;
        flex-wrap: wrap;
        .BatchforFlex {
          display: flex;
          align-items: center;
          padding: 5px 20px;
          cursor: pointer;
          .BatchImgBox {
            display: flex;
            justify-content: center;
            align-items: center;
          }
          img {
            width: 16px;
            height: 16px;
          }
        }
      }
    }
  }
  }
}
</style>
